@import url('https://fonts.googleapis.com/css2?family=Space+Mono&display=swap');
*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --font-color: 230 35% 7%;
/*  background-color:  #090d16; */
background-color: #FBECB2;
}

body {
  color: hsl(var(--font-color));
}

img {
  max-width: 100%;
  display: block;
}

label {
  font-family: 'Space Mono', monospace;
  color: black;
}

.section {
  padding: 2em 0;
  display: flex;
  min-height: 100vh;
  align-items: center;
}

.container {
  margin-inline: auto;
  max-width: 75rem;
  width: 85%;
}

.input__group {
  display: flex;
  margin-top: 2em;
}

input {
  width: 100%;
  padding: 1em 0.75em;
  border: 1px solid black;
  border-radius: 3px;
  border-style: solid;
  border-color: black;
  border-width: 2px;
  margin-bottom: 2em;
  margin-top: 0.75em;
  background-color: #F0F0F0;
}

button {
  border-style: solid;
  border-width: 2px ;
  border-color: black;
  border-radius: 5px;
  color: black;
  padding: 1em 0.75em;
/*  background-color: #E43F5A; */
  background-color: #F8BDEB;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: normal;
  font-family: 'Space Mono', monospace;
  box-shadow: 3px 3px 0px black;

}

canvas {
  border-style: solid ;
  border-radius: 9px;
/*  border-width: 5px;
  border-color: red;
  box-shadow: 10px 10px  rgba(0, 0, 0, 0.822); */
}



.noscript_err{
  font-weight: bold;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: larger;
}

@media screen and (min-width: 768px) {
  .section {
    padding: 0;
  }

  input {
    margin: 0;
  }

  .qrcode__container {
    display: flex;
    align-items: center;
  }

  .input__group {
    margin-left: 3em;
  }

  input {
    margin-bottom: 2em;
    margin-top: 0.75em;
    font-size: 1rem;
    font-family: 'Space Mono', monospace;

  }
}

